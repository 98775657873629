import { Link } from "gatsby";
import * as React from "react";
import Expand from "react-expand-animated";
import { InjectedFormProps, reduxForm } from "redux-form";
import AgreementCheckBoxField from "src/components/AgreementCheckBoxField";
import Button from "src/components/Button";
import DateField from "src/components/DateField";
import FileField from "src/components/DropeZone";
import layoutModuleCss from "src/components/Layout/layout.module.css";
import RadioButtonField from "src/components/RadioButtonField";
import TextField from "src/components/TextInput";
import Typography from "src/components/Typography";
import {SCENARIO_TYPES} from "src/consts";
import {Good} from "src/types";
import { email, isValidURLField } from "src/utils/validatiors";
import scenarioModuleCss from "./scenario.module.css";

interface Props extends InjectedFormProps<FormData> {
  shouldRenderEmailField?: boolean;
  registerError?: boolean;
  good: Good;
}

interface State {
  file?: File;
  name?: string;
  error: string | undefined;
}

export type FormData = {
  user: {
    name: string;
    lastName?: string;
    fatherName?: string;
    dateOfBirth?: string;
    city?: string;
    socialLink?: string;
  };
  email?: string;
  name: string;
  genre: string;
  file: File[];
  onlineСonsultation: string;
  acceptConditions: boolean;
};

class ScenarioForm extends React.Component<Props, State> {
  public render() {
    const { props } = this;
    const isDisabled = !!(
      props.submitting ||
      !props.anyTouched ||
      props.error ||
      props.invalid
    );
    return (
      <div className={scenarioModuleCss.scenarioForm}>
        <div className={scenarioModuleCss.wrapper}>
          <div className={scenarioModuleCss.formContent}>
            <div className={scenarioModuleCss.formHeader}>
              <Typography type="boldText" black>
                Загрузи свой сценарий
              </Typography>
            </div>
            <form
              onSubmit={props.handleSubmit}
              className={scenarioModuleCss.form}
            >
              <div className={scenarioModuleCss.formInputs}>
                <div className={scenarioModuleCss.userSection}>
                  <TextField
                    name="user.lastName"
                    label="Фамилия"
                    black
                    required
                  />
                  <TextField name="user.name" label="Имя" black required />
                  <TextField name="user.phoneNumber" label="Номер телефона" black />
                  <DateField
                    name="user.dateOfBirth"
                    label="Дата рождения"
                    black
                  />
                  <TextField name="user.city" label="Город" black />
                  <TextField
                    name="user.socialLink"
                    label="Ссылка на социальную сеть"
                    black
                    required
                    validate={isValidURLField}
                  />
                  <div className={scenarioModuleCss.radioButtonsContainer}>
                    <label>
                      <Typography type="text" black>
                        Формат онлайн консультации *
                      </Typography>
                    </label>
                    <RadioButtonField
                      name="onlineСonsultation"
                      label="В мессенджере"
                      value="text"
                    />
                    <RadioButtonField
                      name="onlineСonsultation"
                      label="В скайпе"
                      value="video"
                    />
                    {this.props.good.code === SCENARIO_TYPES.EDITORIAL_SUPPORT ? null : (
                      <RadioButtonField
                        name="onlineСonsultation"
                        label="Не нужна"
                        value="none"
                      />
                    )}
                  </div>
                  <AgreementCheckBoxField name="agreement" />
                </div>
                <div className={scenarioModuleCss.separationLine} />
                <div className={scenarioModuleCss.scenarioSection}>
                  {this.props.shouldRenderEmailField ? (
                    <TextField name="email" label="Email" black required />
                  ) : null}
                  <TextField name="name" label="Название" black required />
                  <TextField name="genre" label="Жанр" black required />
                  <FileField name="file" />
                </div>
              </div>
              <div className={scenarioModuleCss.buttonWrapper}>
                <Expand open={props.registerError}>
                  <div>
                    <Typography type="boldText" black>
                      Кажется, аккаунт с таким email, уже существует. Пожалуйста
                      войдите.
                    </Typography>
                    <div style={{ marginTop: "12px" }}>
                      <Link
                        to="/login"
                        className={scenarioModuleCss.buttonWrapper}
                      >
                        <Button>ВХОД</Button>
                      </Link>
                    </div>
                  </div>
                </Expand>
                {!this.props.registerError ? (
                  <Button type="submit" disabled={isDisabled} black>
                    ОТПРАВИТЬ
                  </Button>
                ) : null}
              </div>
            </form>
          </div>
          <div className={layoutModuleCss.formInfo}>
            <Expand open={props.submitting}>
              <Typography type="boldText" black>
                Идет загрузка...
              </Typography>
            </Expand>

            <Expand open={props.error}>
              <Typography type="boldText" black>
                {props.error}
              </Typography>
            </Expand>
          </div>
        </div>
      </div>
    );
  }
}

const validate = (values: FormData): {} => {
  const errors: { email?: string } = {};
  const requiredFields = [
    "lastName",
    "name",
    "genre",
    "file",
    "onlineConsultation",
    "acceptConditions",
    "user.socialLink",
    "agreement",
    "email",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Обязательное поле";
      return;
    }
    if (field === "user.socialLink") {
      errors[field] = isValidURLField(values.user.socialLink);
      return;
    }
  });
  errors.email = email(values.email);
  return errors;
};

export default reduxForm<FormData & {good: Good}>({
  form: "ScenarioForm", // a unique identifier for this form,
  validate,
})(ScenarioForm);
