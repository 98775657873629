import * as React from "react";
import CheckBoxField from "src/components/checkBoxField";
import Typography from "src/components/Typography";
import styles from "./AgreementCheckBoxField.module.css";

class AgreementCheckBoxField extends React.Component<{ name: string }, any> {
  public render() {
    return (
      <div className={styles.checkBoxRegister}>
        <CheckBoxField
          name={this.props.name}
          label={
            <Typography type="smallerText" black>
              {`Согласие с `}
              <a
                href="https://potentialfilm.ru/personal_information"
                target="blank"
              >
                лицензионным договором
              </a>
              {` и `}
              <a href="https://potentialfilm.ru/policy" target="blank">
                пс
              </a>
            </Typography>
          }
        />
      </div>
    );
  }
}

export default AgreementCheckBoxField;
