import * as React from "react";
import ModalWrapper from "src/components/ModalWrapper";
import { Good } from "src/types";
import FormContainer from "./FormContainer";
import scenarioModuleCss from "./scenario.module.css";

interface Props {
  good: Good;
}

interface State {
  submitting: boolean;
}

class ScenarioFormContainer extends React.Component<Props, State> {
  public state = {
    submitting: false,
  };

  public render() {
    return (
      <ModalWrapper>
        <div className={scenarioModuleCss.modalFormWrapper}>
          <FormContainer
            handleClose={this.handleCloseModal}
            changeSubmitting={this.handleSubmitting}
            fileUploading={this.state.submitting}
            good={this.props.good}
          />
        </div>
      </ModalWrapper>
    );
  }

  public handleSubmitting = (submitting: boolean) => {
    this.setState({ submitting });
  };

  private handleCloseModal = () => {
    if (!this.state.submitting) {
      // this.props.handleClose();
    }
  };
}

export default ScenarioFormContainer;
