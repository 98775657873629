import * as React from "react";
import { Field, WrappedFieldProps } from "redux-form";
import Typography from "src/components/Typography";
import allowSvg from "src/images/allow.svg";
import checkBoxFieldModuleCss from "./checkBoxField.module.css";

interface Props {
  name: string;
  label: React.ReactNode;
  value: string;
}

class CheckBox extends React.Component<Props & WrappedFieldProps> {
  public render() {
    return (
      <div className={checkBoxFieldModuleCss.checkBoxContainer}>
        {
          // @ts-ignore
          <CheckBoxInput input={this.props.input} />
        }
        <Typography type="smallerText">{this.props.label}</Typography>
      </div>
    );
  }
}

class CheckBoxInput extends React.Component<
  WrappedFieldProps,
  { value: boolean }
> {
  public state = {
    value: false,
  };
  public render() {
    return (
      <div
        role="button"
        onClick={this.handleChange}
        className={checkBoxFieldModuleCss.checkBox}
      >
        {this.state.value && (
          <div className={checkBoxFieldModuleCss.checkBoxImgWrapper}>
            <img src={allowSvg} alt="OK" />
          </div>
        )}
      </div>
    );
  }

  private handleChange = () => {
    this.props.input.onChange(!this.state.value);
    this.setState({ value: !this.state.value });
  };
}

const CheckBoxField = (props: Props) => (
  <Field name={props.name} props={props} component={CheckBox} />
);

export default CheckBoxField;
