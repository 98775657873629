import * as React from "react";

import { navigate } from "gatsby";
import ScenarioForm from "src/components/ScenarioForm";
import Seo from "src/components/Seo";
import { Good, PageProps } from "src/types";

interface Props extends Omit<PageProps, "location"> {
  location: {
    state: {
      good: Good;
    };
  };
}

class ScenarioFormPage extends React.Component<Props> {
  public async componentDidMount() {
    if (!this.props.location?.state?.good) {
      await navigate("/send-scenario");
    }
  }

  public render() {
    if (!this.props.location?.state?.good) return null;

    const { good } = this.props.location.state;

    return (
      <>
        <Seo pageTitle="Форма загрузки сценария" />
        <ScenarioForm good={good} />
      </>
    );
  }
}

export default ScenarioFormPage;
