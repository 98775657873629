import { navigate } from "gatsby";
import * as React from "react";
import { connect } from "react-redux";
import { FormSubmitHandler } from "redux-form";
import Api from "src/api";
import { actions as userActions } from "src/store/User";
import { Good, User } from "src/types";
import ScenarioForm from "./ScenarioForm";
// tslint:disable-next-line: no-duplicate-imports
import { FormData as FormDataType } from "./ScenarioForm";

interface Props extends ConnectStateProps, ConnectDispatchProps {
  fileUploading: boolean;
  good: Good;
  handleClose(): void;
  changeSubmitting(sub: boolean): void;
}

interface State {
  registerError: boolean;
  error?: string;
}

class FormContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      registerError: false,
    };
  }

  public render() {
    return (
      <ScenarioForm
        onSubmit={this.handleSubmit}
        initialValues={{
          user: this.props.user,
        }}
        registerError={this.state.registerError}
        shouldRenderEmailField={!this.props.user}
        good={this.props.good}
      />
    );
  }

  private handleSubmit: FormSubmitHandler<FormDataType> = async (data) => {
    this.props.changeSubmitting(true);

    if (!this.props.user) {
      const isValid = await this.props.createUserWithoutPassword({
        email: data.email,
        name: data.user.name,
        lastName: data.user.lastName,
      });

      if (!isValid) {
        this.setState({ registerError: true });
        this.props.changeSubmitting(false);
        return;
      }
      this.props.getUser();
    }

    try {
      const formData = this.createFormData(data.file[0], data.name);
      const res = await Api.uploadFile(formData);
      data.file = undefined;
      const scenarioRes = await Api.uploadScenario({
        ...data,
        url: res.data.url,
        name: data.name,
        type: this.props.good.code,
        userData: data.user,
      });

      this.props.changeSubmitting(false);
      this.props.handleClose();

      await navigate("/pay", {
        state: {
          good: this.props.good,
          scenarioId: scenarioRes.data.scenario._id,
        },
      });
      return;
    } catch (e) {
      this.props.changeSubmitting(false);
      this.setState({ error: "Ошибка загрузки" });
      console.error(e);
    }
  };

  private createFormData = (file: File, name: string) => {
    const formData = new FormData();
    formData.append("doc", file);
    formData.append("type", "scenarios");
    formData.append("name", name);
    return formData;
  };
}

interface ConnectStateProps {
  user: User;
}

interface ConnectDispatchProps {
  getUser: typeof userActions.getUser;
  login(email: string, password: string): Promise<boolean>;
  createUserWithoutPassword(LoginData): Promise<boolean>;
}

const mapActionsToProps = {
  login: userActions.login,
  createUserWithoutPassword: userActions.createUserWithoutPassword,
  getUser: userActions.getUser,
};

const mapStateToProps = (state) => ({
  // tslint:disable no-unsafe-any
  user: state.user.user,
});

export default connect<ConnectStateProps, ConnectDispatchProps>(
  mapStateToProps,
  // @ts-ignore
  mapActionsToProps
)(FormContainer);
