import React from "react";
import { useDropzone } from "react-dropzone";
import { Field, WrappedFieldProps } from "redux-form";
import Typography from "src/components/Typography";
import docIcon from "src/images/pdf-icon.svg";
import plusIcon from "src/images/plus.svg";
import styles from "./DropeZone.module.css";

interface Props extends WrappedFieldProps {}

const DropzoneInput = (props: Props) => {
  const {
    input: { onChange },
  } = props;

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf",
    maxSize: 10.1 * 1024 * 1024,
    onDrop: (files) => onChange(files),
  });

  const file: File = props.input?.value?.[0];
  return (
    <div className={styles.container}>
      <div className={styles.outterWrapper}>
        <div
          {...getRootProps({
            multiple: false,
            disabled: props.meta.submitting,
          })}
          className={styles.innerWrapper}
        >
          <input
            {...getInputProps({ multiple: false })}
            type="file"
            accept="application/pdf"
          />
          {renderDropezone()}
        </div>
      </div>
      {file?.size && renderFile(file)}
    </div>
  );
};

const renderDropezone = () => {
  return (
    <div>
      <div className={styles.beforeUpload}>
        <img src={plusIcon} alt="добавить файл" />
        <Typography type="text" black>
          Выбери файл или перетащи его{" "}
        </Typography>
        <Typography type="smallerText" black>
          Тип файла - pdf. Размер не должен превышать 10 MB
        </Typography>
      </div>
    </div>
  );
};

const renderFile = (file: File) => {
  return (
    <div className={styles.complete}>
      <img src={docIcon} alt="файл" />
      <div className={styles.fileDescription}>
        <Typography type="boldText" black>
          {file.name}
        </Typography>
        <Typography type="smallerText" black>
          {`${getFileSize(file.size)} MB`}
        </Typography>
      </div>
    </div>
  );
};

const getFileSize = (size: number) => {
  const amount = size / 1024 / 1024;
  return amount < 1 ? amount.toFixed(1) : Math.round(amount);
};
//

const FileField = (props: { name: string }) => (
  <Field name={props.name} props={props} component={DropzoneInput} />
);

export default FileField;
