import * as React from "react";
import DateInput from "src/components/DateInput";

import { Field, WrappedFieldProps } from "redux-form";

interface Props {
  name: string;
  type?: string;
  label: string;
  black?: boolean;
  required?: boolean;
}

class Adapter extends React.Component<Props & WrappedFieldProps, any> {
  public render() {
    return (
      <DateInput
        handleChange={this.props.input.onChange}
        handleBlur={this.props.input.onBlur}
        value={this.props.input.value}
        placeholder={this.props.label}
        black
      />
    );
  }
}

const DateField = (props: Props) => (
  <Field name={props.name} props={props} component={Adapter} />
);

export default DateField;
