import * as React from "react";
import { Field, WrappedFieldProps } from "redux-form";
import Typography from "src/components/Typography";
import styles from "./RadioButtonField.module.css";

type Props = {
  name: string;
  label: string;
  value: string;
};

class RadioButtonInput extends React.Component<Props & WrappedFieldProps> {
  public handleChange = () => {
    this.props.input.onChange(this.props.value);
  };

  public render() {
    return (
      <div className={styles.wrapper} onClick={this.handleChange} role="button">
        <div
          className={`${styles.checkbox} ${
            this.props.input.checked ? styles.checked : ""
          }`}
        />
        <label className={styles.label}>
          <Typography type="smallerText" black>
            {this.props.label}
          </Typography>
        </label>
      </div>
    );
  }
}

const RadioButtonField = (props: Props) => (
  <Field
    name={props.name}
    props={props}
    component={RadioButtonInput}
    type="radio"
    value={props.value}
  />
);

export default RadioButtonField;
